<template>
    <div class="card p-fluid">
        <h5>Outros</h5>
        <div class="field col-12 md:col-12">
            <label>Tipo de Execução</label>
            <Dropdown :options="tipoExecucao" v-model="solucao.tipoExecucao" showClear />
        </div>
        <div class="field col-12 md:col-12">
            <label class="required">Palavras Chave</label>
            <Textarea
                id="palavrasChave"
                v-model="solucao.palavrasChave"
                rows="5"
                :autoResize="true"
                v-bind:class="{ 'p-invalid': v$.solucao.palavrasChave.$invalid && v$.solucao.palavrasChave.$dirty }"
                @input="v$.solucao.palavrasChave.$touch()"
            />
        </div>
        <div class="field-checkbox col-10">
            <Checkbox id="binary" v-model="solucao.solucaoCertificadaSebrae" :binary="true" />
            <label for="binary">Solução Certificada pelo SEBRAE Nacional</label>
        </div>
    </div>
    <div class="ml-6">
        <div class="flex mr-5 justify-content-end">
            <Button label="Voltar" @click.stop="voltar" icon="pi pi-arrow-left" class="ml-2 p-button-secondary"></Button>
            <Button class="ml-2" label="Próximo" icon="pi pi-arrow-right" iconPos="right" @click.stop="proximo"></Button>
        </div>
    </div>
</template>

<script>
import Service from './service';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
    emits: ['voltar', 'proximo', 'salvar'],
    props: {
        objeto: Object,
        onInserir: Boolean,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            solucao: {
                tipoExecucao: null,
                palavrasChave: null,
                solucaoCertificadaSebrae: null,
            },
            tipoExecucao: null,
        };
    },
    validations() {
        return {
            solucao: {
                palavrasChave: { required },
            },
        };
    },
    mounted() {
        this.solucao = this.objeto;
        this.obterTiposExecucao();
    },
    methods: {
        proximo() {
            this.$emit('proximo');
        },
        voltar() {
            this.$emit('voltar');
        },
        obterTiposExecucao() {
            this.$store.dispatch('addRequest');
            Service.obterTiposExecucao().then((response) => {
                if (response?.success) {
                    this.tipoExecucao = response.data;

                    if (this.$route.params.chamado) {
                        let chamado = JSON.parse(this.$route.params.chamado);
                        let modalidade = chamado.origem.solicitacaoProdutoReal.modalidade;

                        let tipoExecucao = this.tipoExecucao.map((item) => {
                            if (item === 'ONLINE' && modalidade === 'Remoto') {
                                return item;
                            }

                            if (item === 'PRESENCIAL' && modalidade === 'Presencial') {
                                return item;
                            }

                            if (item === 'AMBAS' && modalidade === 'Híbrido') {
                                return item;
                            }

                            return null;
                        })[0];

                        this.solucao.tipoExecucao = tipoExecucao;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    watch: {
        solucao: {
            handler() {
                this.$emit('salvar', this.solucao);
            },
            deep: true,
        },
        onInserir() {
            if (this.onInserir == true) {
                this.v$.solucao.$touch();
            }
        },
    },
};
</script>

<style></style>

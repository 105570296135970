<template>
    <div class="card p-fluid">
        <h5>Orientações para Oferta</h5>
        <div class="field col-12 md:col-12">
            <label class="required">Público Alvo</label>
            <MultiSelect
                v-model="solucao.publicoAlvo"
                :options="publicoAlvo"
                optionLabel="title"
                showClear
                v-bind:class="{ 'p-invalid': v$.solucao.publicoAlvo.$invalid && v$.solucao.publicoAlvo.$dirty }"
                @input="v$.solucao.publicoAlvo.$touch()"
            />
        </div>
        <div class="field col-12 md:col-12">
            <label class="required">Setor Econômico</label>
            <MultiSelect
                v-model="solucao.setoresEconomicos"
                :options="setorEconomico"
                optionLabel="title"
                showClear
                v-bind:class="{ 'p-invalid': v$.solucao.setoresEconomicos.$invalid && v$.solucao.setoresEconomicos.$dirty }"
                @input="v$.solucao.setoresEconomicos.$touch()"
            />
        </div>
        <div class="field col-12 md:col-12">
            <label class="required">Macrossegmento Econômico</label>
            <MultiSelect
                v-model="solucao.macrossegmentosEconomicos"
                :options="macrossegmentoEconomico"
                optionLabel="title"
                showClear
                v-bind:class="{ 'p-invalid': v$.solucao.macrossegmentosEconomicos.$invalid && v$.solucao.macrossegmentosEconomicos.$dirty }"
                @input="v$.solucao.macrossegmentosEconomicos.$touch()"
            />
        </div>
        <!-- <div class="field col-12 md:col-12">
            <label>Personas</label>
            <MultiSelect v-model="solucao.personas" :options="personas" optionLabel="title" showClear />
        </div> -->
        <div class="field col-12 md:col-12">
            <label class="required">Texto de venda</label>
            <Editor
                v-model="solucao.textoVenda"
                editorStyle="height: 120px"
                v-bind:class="{ 'p-editor-container1': v$.solucao.textoVenda.$invalid && v$.solucao.textoVenda.$dirty }"
                @input="v$.solucao.textoVenda.$touch()"
            />
        </div>
        <div class="field col-12 md:col-12">
            <label>Politica de Preços</label>
            <Dropdown v-model="solucao.politicaPreco" :options="politicaPrecos" optionLabel="title" showClear />
        </div>
        <div class="field col-12 md:col-12">
            <label>Observações</label>
            <Editor v-model="solucao.observacoesOrientacoes" editorStyle="height: 120px" />
        </div>
    </div>
    <div class="ml-6">
        <div class="flex mr-5 justify-content-end">
            <Button label="Voltar" @click.stop="voltar" icon="pi pi-arrow-left" class="ml-2 p-button-secondary"></Button>
            <Button class="ml-2" label="Próximo" icon="pi pi-arrow-right" iconPos="right" @click.stop="proximo"></Button>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import PublicoAlvoService from '../publicoalvo/service';
import MacrossegmentoService from '../macrossegmentoeconomico/service';
//import PersonasService from '../personas/service';
import PoliticaPrecosService from '../politicapreco/service';
import SetorEconomicoService from '../setoreconomico/service';

export default {
    emits: ['voltar', 'proximo', 'salvar'],
    props: {
        objeto: Object,
        onInserir: Boolean,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            solucao: {
                publicoAlvo: [],
                macrossegmentosEconomicos: [],
                personas: [],
                setoresEconomicos: [],
                politicaPreco: null,
                textoVenda: null,
                observacoesOrientacoes: null,
            },
            publicoAlvo: null,
            macrossegmentoEconomico: null,
            personas: null,
            politicaPrecos: null,
            setorEconomico: null,
        };
    },
    validations() {
        return {
            solucao: {
                publicoAlvo: { required },
                macrossegmentosEconomicos: { required },
                setoresEconomicos: { required },
                textoVenda: { required },
            },
        };
    },
    mounted() {
        this.solucao = this.objeto;
        this.obterPublicoAlvo();
        this.obterMacrossegmento();
        //this.obterPersonas();
        this.obterPoliticaPrecos();
        this.obterSetorEconomico();
    },
    methods: {
        proximo() {
            this.$emit('proximo');
        },
        voltar() {
            this.$emit('voltar');
        },
        obterPublicoAlvo() {
            this.$store.dispatch('addRequest');
            PublicoAlvoService.obterTodos().then((response) => {
                if (response?.success) {
                    this.publicoAlvo = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterMacrossegmento() {
            this.$store.dispatch('addRequest');
            MacrossegmentoService.obterTodos().then((response) => {
                if (response?.success) {
                    this.macrossegmentoEconomico = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        /* obterPersonas() {
            this.$store.dispatch('addRequest');
            PersonasService.obterTodos().then((response) => {
                if (response?.success) {
                    this.personas = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        }, */
        obterPoliticaPrecos() {
            this.$store.dispatch('addRequest');
            PoliticaPrecosService.obterTodos().then((response) => {
                if (response?.success) {
                    this.politicaPrecos = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterSetorEconomico() {
            this.$store.dispatch('addRequest');
            SetorEconomicoService.obterTodos().then((response) => {
                if (response?.success) {
                    this.setorEconomico = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    watch: {
        solucao: {
            handler() {
                this.$emit('salvar', this.solucao);
            },
            deep: true,
        },
        onInserir() {
            if (this.onInserir == true) {
                this.v$.solucao.$touch();
            }
        },
    },
};
</script>

<style scoped>
.p-editor-container1 {
    border: 0.1px solid rgb(255, 81, 69);
    border-radius: 4px;
}
</style>

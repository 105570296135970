import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol`;
const urlBase = `${api}/setoreconomico`;

export default {
    obterTodos(active) {
        active == null ? active = '' : active
        return axiosJwt.get(`${urlBase}?active=${active}`);
    },
    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },
    excluir(id) {
        return axiosJwt.delete(`${urlBase}/${id}`);
    },
    inserir(setorEconomico) {
        return axiosJwt.post(`${urlBase}`, setorEconomico);
    },
    atualizar(id, setorEconomico) {
        return axiosJwt.put(`${urlBase}/${id}`, setorEconomico);
    },
    obterSetorSas() {
        return axiosJwt.get(`${api}/sas/setores`);
    },
};
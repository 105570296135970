<template>
    <div class="card p-fluid">
        <h5>Integração SAS</h5>
        <div class="field col-12 md:col-12">
            <label>Cod. Solução SAS</label>
            <InputText v-model="solucao.codSolucaoSas" type="text" />
        </div>
        <!-- <div class="field col-12 md:col-12">
            <label>Complexidade</label>
            <Dropdown v-model="solucao.complexidade" :options="complexidade" optionLabel="title" showClear />
        </div> 
        <div class="field col-12 md:col-12">
            <label>Metodologia</label>
            <Dropdown v-model="solucao.metodologia" :options="metodologia" optionLabel="title" showClear />
        </div> -->
        <div class="field col-12 md:col-12">
            <label>Complexidade Metodologias</label>
            <Dropdown v-model="solucao.complexidadeMetodologia" :options="complexidadeMetodologia" optionLabel="title" showClear />
        </div>
        <div class="field-checkbox col-10">
            <Checkbox v-model="solucao.emiteCertificado" id="binary" :binary="true" />
            <label for="binary">Emite Certificado</label>
        </div>
        <div class="field col-12 md:col-12">
            <label class="required">Unidade Organizacional</label>
            <Dropdown
                v-model="solucao.codigoUnidadeOrganizacional"
                :options="codigoUnidadeOrganizacional"
                optionValue="CodigoUnidadeOrganizacional"
                optionLabel="Descricao"
                showClear
                v-bind:class="{ 'p-invalid': v$.solucao.codigoUnidadeOrganizacional.$invalid && v$.solucao.codigoUnidadeOrganizacional.$dirty }"
                @input="v$.solucao.codigoUnidadeOrganizacional.$touch()"
            />
        </div>
        <div class="field col-12 md:col-12">
            <label>URL da Solução</label>
            <InputText v-model="solucao.urlSolucao" type="text" />
        </div>
    </div>
    <div class="ml-6">
        <div class="flex mr-5 justify-content-end">
            <Button label="Voltar" @click.stop="voltar" icon="pi pi-arrow-left" class="ml-2 p-button-secondary"></Button>
            <Button class="ml-2" icon="pi pi-check-circle" label="Salvar" @click.stop="salvar"></Button>
        </div>
    </div>
</template>

<script>
import ComplexidadeService from '../complexidade/service';
import MetodologiaService from '../metodologia/service';
import ComplexidadeMetodologiaService from '../complexidademetodologia/service';
import Service from './service';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
    emits: ['voltar', 'salvar', 'inserir'],
    props: {
        objeto: Object,
        isInserir: Boolean,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            solucao: {
                complexidade: null,
                metodologia: null,
                complexidadeMetodologia: null,
                codigoUnidadeOrganizacional: null,
                unidadeOrganizacional: null,
                codSolucaoSas: null,
                emiteCertificado: null,
                urlSolucao: null,
            },
            unidades: [],
            complexidade: null,
            metodologia: null,
            complexidadeMetodologia: [],
            codigoUnidadeOrganizacional: null,
        };
    },
    validations() {
        return {
            solucao: {
                codigoUnidadeOrganizacional: { required },
            },
        };
    },
    mounted() {
        this.solucao = this.objeto;
        this.obterComplexidades();
        this.obterMetodologias();
        this.obterUnidadeOrganizacional();
        this.obterComplexidadeMetodologias();
    },
    methods: {
        salvar() {
            this.$emit('inserir');
        },
        voltar() {
            this.$emit('voltar');
        },
        obterComplexidades() {
            this.$store.dispatch('addRequest');
            ComplexidadeService.obterTodos().then((response) => {
                if (response?.success) {
                    this.complexidade = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterMetodologias() {
            this.$store.dispatch('addRequest');
            MetodologiaService.obterTodos().then((response) => {
                if (response?.success) {
                    this.metodologia = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterUnidadeOrganizacional() {
            this.$store.dispatch('addRequest');
            Service.obterUnidadeOrganizacional().then((response) => {
                if (response?.success) {
                    this.codigoUnidadeOrganizacional = response.data;
                    this.unidades = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: response.errors[0],
                        life: 10000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterComplexidadeMetodologias() {
            this.$store.dispatch('addRequest');
            ComplexidadeMetodologiaService.obterTodos().then((response) => {
                if (response?.success) {
                    this.complexidadeMetodologia = response.data;

                    if (this.$route.params.chamado) {
                        let chamado = JSON.parse(this.$route.params.chamado);

                        let complexidade = this.complexidadeMetodologia.filter(
                            (complexidade) => complexidade.title == chamado.origem.solicitacaoProdutoReal.complexidade
                        )[0];

                        this.solucao.complexidadeMetodologia = complexidade;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    watch: {
        solucao: {
            handler() {
                this.$emit('salvar', this.solucao);
            },
            deep: true,
        },
        'solucao.codigoUnidadeOrganizacional'() {
            if (this.solucao.codigoUnidadeOrganizacional > 0 && this.unidades.length > 0) {
                const result = this.unidades.filter((unidade) => unidade.CodigoUnidadeOrganizacional == this.solucao.codigoUnidadeOrganizacional);
                this.solucao.unidadeOrganizacional = result[0].Descricao;
                this.solucao.codigoUnidadeOrganizacional = result[0].CodigoUnidadeOrganizacional;
            }
        },
        isInserir() {
            if (this.isInserir == true) {
                this.v$.solucao.$touch();
            }
        },
    },
};
</script>

<style></style>

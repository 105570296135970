<template>
    <div>
        <Dialog v-model:visible="displayModal" :breakpoints="{ '640px': '60vw' }" :style="{ width: '400px' }" :modal="true" dismissableMask>
            <div>
                <div class="card">
                    <Steps :model="items" :readonly="true" :exact="false" />
                    <div v-if="index == 0" class="flex-column justify-content-center flex-wrap card-container">
                        <h4 class="flex justify-content-center">Você deseja publicar a alteração?</h4>
                        <SelectButton
                            class="mt-6 flex justify-content-center"
                            v-model="solucao.publicado"
                            :options="options"
                            optionLabel="label"
                            optionValue="value"
                            aria-labelledby="single"
                            @input="v$.solucao.publicado.$touch()"
                        />
                        <Message
                            severity="error"
                            :closable="false"
                            :sticky="true"
                            v-if="publicadoInvalid"
                            class="bg-red-200 flex justify-content-center flex-wrap card-container yellow-container"
                            >Você precisa selecionar algum!</Message
                        >
                    </div>
                    <div v-if="index == 1" class="flex-column justify-content-center flex-wrap card-container">
                        <h4 class="flex justify-content-center">Selecione o motivo da edição</h4>
                        <Dropdown
                            class="mt-7 flex justify-content-center"
                            v-model="solucao.motivoEdicao"
                            :options="motivosEdicao"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione uma opção"
                            @input="v$.solucao.motivoEdicao.$touch()"
                        />
                        <Message
                            severity="error"
                            :closable="false"
                            :sticky="true"
                            v-if="motivoInvalid"
                            class="bg-red-200 flex justify-content-center flex-wrap card-container yellow-container"
                            >Você precisa selecionar algum!</Message
                        >
                    </div>
                    <div v-if="index == 2" class="flex-column justify-content-center flex-wrap card-container">
                        <h4 class="flex justify-content-center">Justifique</h4>
                        <div class="my-4 flex align-items-center justify-content-center">
                            <Textarea
                                v-model="solucao.justificativaAlteracao"
                                @input="v$.solucao.justificativaAlteracao.$touch()"
                                :autoResize="true"
                                rows="4"
                                cols="80"
                            />
                        </div>
                        <Message
                            severity="error"
                            :closable="false"
                            :sticky="true"
                            v-if="justificativaInvalid"
                            class="bg-red-200 flex justify-content-center flex-wrap card-container yellow-container"
                            >Insira a justificativa!</Message
                        >
                    </div>
                </div>
                <div class="flex justify-content-between flex-wrap card-container">
                    <Button :disabled="index < 1" label="Voltar" icon="pi pi-arrow-left" class="mr-2 p-button-secondary" @click="prevPage"></Button>
                    <Button v-if="index < 2" label="Próximo" icon="pi pi-arrow-right" iconPos="right" @click="nextPage"></Button>
                    <Button v-if="index == 2" label="Salvar" @click="salvar" icon="pi pi-check-circle"></Button>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import Service from './service';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
    props: {
        modal: {
            type: Boolean,
        },
        objeto: {
            type: Number,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            solucao: {
                publicado: null,
                justificativaAlteracao: null,
                motivoEdicao: null,
            },
            index: null,
            options: [
                {
                    label: 'Sim',
                    value: true,
                },
                {
                    label: 'Não',
                    value: false,
                },
            ],
            items: [
                {
                    label: null,
                    to: '',
                },
                {
                    label: null,
                    to: '',
                },
                {
                    label: null,
                    to: '',
                },
            ],
            displayModal: false,
            position: 'center',
            motivosEdicao: [],
            publicadoInvalid: null,
            motivoInvalid: null,
            justificativaInvalid: null,
        };
    },
    mounted() {
        this.obterMotivosEdicao();
        if (this.modal) {
            this.index = 0;
            this.items[this.index].to = this.$router.currentRoute.value.fullPath;
            this.displayModal = true;
        }
    },
    validations() {
        return {
            solucao: {
                publicado: { required },
                justificativaAlteracao: { required },
                motivoEdicao: { required },
            },
        };
    },
    methods: {
        prevPage() {
            if (this.index > 0) {
                this.items[this.index].to = null;
                this.index--;
            }
        },
        nextPage() {
            if (this.index < 2) {
                if (this.v$.solucao.publicado.$invalid && this.index == 0) {
                    this.publicadoInvalid = true;
                } else if (this.v$.solucao.motivoEdicao.$invalid && this.index == 1) {
                    this.motivoInvalid = true;
                } else {
                    this.index++;
                    this.items[this.index].to = this.$router.currentRoute.value.fullPath;
                }
            }
        },
        salvar() {
            if (this.v$.solucao.justificativaAlteracao.$invalid) {
                this.justificativaInvalid = true;
            } else {
                this.$emit('atualizar', this.solucao);
            }
        },
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
        obterMotivosEdicao() {
            this.$store.dispatch('addRequest');
            Service.obterMotivosEdicao().then((response) => {
                if (response?.success) {
                    var obj = response.data;
                    var array = Object.keys(obj).map(function (key) {
                        return {
                            label: obj[key],
                            value: key,
                        };
                    });
                    this.motivosEdicao = array;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    watch: {
        displayModal() {
            if (!this.displayModal) {
                this.$emit('closeModal');
            }
        },
        'solucao.publicado'() {
            if (!this.v$.solucao.publicado.$invalid) {
                this.publicadoInvalid = false;
            }
        },
        'solucao.motivoEdicao'() {
            if (!this.v$.solucao.publicado.$invalid) {
                this.motivoInvalid = false;
            }
        },
        'solucao.justificativaAlteracao'() {
            if (!this.v$.solucao.publicado.$invalid) {
                this.justificativaInvalid = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-steps-number) {
    margin-bottom: 10px;
    min-height: 50px;
    width: 50px;
}
</style>

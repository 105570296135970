<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>Informações Gerais do Produto/Serviço</h5>
                <div class="field col-12 md:col-12">
                    <label class="required">Título</label>
                    <InputText
                        type="text"
                        v-model="solucao.title"
                        @input="v$.solucao.title.$touch()"
                        v-bind:class="{ 'p-invalid': v$.solucao.title.$invalid && v$.solucao.title.$dirty }"
                    />
                </div>
                <!-- <div class="field col-12 md:col-12">
                    <label>Nome Comercial</label>
                    <InputText type="text" v-model="solucao.nomeComercial" />
                </div> -->
                <div class="field col-12 md:col-12">
                    <label>Objetivo/Justificativa</label>
                    <Editor v-model="solucao.justificativa" editorStyle="height: 120px" />
                </div>
                <div class="field col-12 md:col-12">
                    <label class="required">Resultado Esperado</label>
                    <Editor
                        v-model="solucao.resultadoEsperado"
                        editorStyle="height: 120px"
                        v-bind:class="{ 'p-editor-container1': v$.solucao.resultadoEsperado.$invalid && v$.solucao.resultadoEsperado.$dirty }"
                        @input="v$.solucao.resultadoEsperado.$touch()"
                    />
                </div>
                <div class="field col-12 md:col-12">
                    <label>Descrição das Atividades / Materiais Utilizados</label>
                    <Editor v-model="solucao.descricaoAtividadesMateriais" editorStyle="height: 120px" />
                </div>
                <div class="field col-12 md:col-12">
                    <label>Produto de Entrega</label>
                    <Editor v-model="solucao.produtoEntrega" editorStyle="height: 120px" />
                </div>
                <div class="field col-12 md:col-12">
                    <label class="required">Instrumento</label>
                    <Dropdown
                        v-model="solucao.tipoServico"
                        :options="tipoServico"
                        optionLabel="tituloComposto"
                        v-bind:class="{ 'p-invalid': v$.solucao.tipoServico.$invalid && v$.solucao.tipoServico.$dirty }"
                        @input="v$.solucao.tipoServico.$touch()"
                        showClear
                    />
                </div>
                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="solucao.atualizarValorHora" :binary="true" />
                    <label for="binary">Atualizar valor da hora a partir da Politica de Preços</label>
                </div>
                <!-- <h5>Dores que ela Resolve</h5>
                <div class="field col-12 md:col-12">
                    <div class="grid row align-items-end">
                        <div class="field col-12 md:col-6">
                            <label>Dores que ela resolve</label>
                            <Dropdown v-if="dor != null" v-model="solucaoDor.dor" :options="dor" optionLabel="title" @input="v$.solucaoDor.dor.$touch()" />
                        </div>
                        <div class="field col-12 md:col-3">
                            <label>O quanto ela resolve (%)</label>
                            <InputNumber
                                v-model="quantoResolve"
                                inputId="percent"
                                suffix="%"
                                :max="100"
                                :min="0"
                                @input="inputNumber($event, solucaoDor, 'quantoResolve', this.v$.solucaoDor.quantoResolve)"
                            />
                        </div>
                        <div class="field col-12 md:col-3">
                            <Button label="Adicionar" @click.stop="inserirSolucaoDor" :disabled="v$.solucaoDor.$invalid"></Button>
                        </div>
                    </div>
                    <DataTable v-if="solucao.dores.length > 0" :value="solucao.dores" responsiveLayout="scroll">
                        <Column field="dor.title" header="Dores que ela resolve"></Column>
                        <Column field="quantoResolve" bodyClass="" header="O quanto ela resolve (%)">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.quantoResolve }}%</span>
                            </template>
</Column>
<Column header="Ações">
    <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="p-button-text p-button-warning mr-2" @click="editarDor(slotProps.data)" />
                                <Button icon="pi pi-trash" class="p-button-text p-button-danger" @click="excluirDor(slotProps.data)" />
                            </template>
</Column>
</DataTable>
<p class="font-italic text-center" v-else>Não existem dores adicionadas.</p>
</div> -->
                <h5>Atividade(s) a ser(em) Desenvolvida(s) / Conteúdo Programático</h5>
                <div class="field col-12 md:col-12">
                    <div class="grid row align-items-start justify-content-between">
                        <div class="field col-12 md:col-5">
                            <label class="required">Descrição</label>
                            <Textarea
                                v-model="atividadeSolucao.descricao"
                                @input="v$.atividadeSolucao.descricao.$touch()"
                                :autoResize="true"
                                rows="6"
                                cols="35"
                            />
                        </div>
                        <div class="flex col-12 md:col-7 p-0 flex-wrap">
                            <div class="flex row p-0 col-12 align-items-end">
                                <div class="field pb-0 col-3">
                                    <label class="required">Qnt Horas</label>
                                    <InputMask v-model="hora" mask="99:99" />
                                </div>
                                <div class="field pb-0 col-4">
                                    <label>Agrupador</label>
                                    <Dropdown v-model="atividadeSolucao.agrupador" :options="agrupador" optionLabel="title" :showClear="true" />
                                </div>
                                <div class="field pb-0 col-5">
                                    <label class="required">Tipo de Serviço</label>
                                    <Dropdown
                                        v-model="atividadeSolucao.tipoServico"
                                        @input="v$.atividadeSolucao.tipoServico.$touch()"
                                        :options="tipoServico"
                                        optionLabel="tituloComposto"
                                        :showClear="true"
                                    />
                                </div>
                            </div>
                            <div class="flex row p-0 col-12 align-items-end">
                                <div class="field pb-0 col-3">
                                    <label class="required">Valor da Hora</label>
                                    <InputNumber
                                        inputId="currency-br"
                                        @input="inputNumber($event, atividadeSolucao, 'valorHora', this.v$.atividadeSolucao.valorHora)"
                                        v-model="atividadeSolucao.valorHora"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        :disabled="atividadeSolucao.agrupador"
                                    />
                                </div>
                                <div class="field pb-0 col-4">
                                    <label class="required">Valor por Etapa</label>
                                    <InputNumber
                                        inputId="currency-br"
                                        @input="inputNumber($event, atividadeSolucao, 'valorEtapa', this.v$.atividadeSolucao.valorEtapa)"
                                        v-model="atividadeSolucao.valorEtapa"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                    />
                                </div>
                                <div class="field pb-0 col-5">
                                    <label>Remuneração Credenciado</label>
                                    <InputNumber
                                        disabled
                                        inputId="currency-br"
                                        v-model="atividadeSolucao.remuneracaoCredenciado"
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid justify-content-end">
                        <div class="field flex row col-12 md:col-3">
                            <Button label="Cancelar" v-if="isEditarAtividade" @click.stop="cancelar('atividade')" class="p-button-secondary"></Button>
                            <Button
                                :label="isEditarAtividade ? 'Atualizar' : 'Adicionar'"
                                class="ml-2"
                                @click.stop="inserirAtividades"
                                :disabled="v$.atividadeSolucao.$invalid"
                            ></Button>
                        </div>
                    </div>
                    <DataTable v-if="solucao.atividades.length > 0" :value="solucao.atividades" responsiveLayout="scroll">
                        <Column field="descricao" header="Descrição"></Column>
                        <Column field="quantidadeHora" header="Qnt de Horas">
                            <template #body="slotProps">
                                <span>{{ decimalToHours(slotProps.data.quantidadeHora) }}</span>
                            </template>
                        </Column>
                        <Column field="valorEtapa" bodyClass="" header="Valor por Etapa">
                            <template #body="slotProps">
                                <span>R$ {{ formataValores(slotProps.data.valorEtapa) }}</span>
                            </template>
                        </Column>
                        <Column field="tipoServico.tituloComposto" header="Tipo de Serviços"></Column>
                        <Column field="remuneracaoCredenciado" header="Remuneração Credenciado">
                            <template #body="slotProps">
                                <span>R$ {{ formataValores(slotProps.data.remuneracaoCredenciado) }}</span>
                            </template>
                        </Column>
                        <Column field="agrupador.title" header="Agrupador"></Column>
                        <Column header="Ações">
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="p-button-text p-button-warning mr-2" @click="editarAtividade(slotProps.data)" />
                                <Button icon="pi pi-trash" class="p-button-text p-button-danger" @click="excluirAtividade(slotProps.data)" />
                            </template>
                        </Column>
                        <template v-if="solucao.atividades.length >= 1" #footer>Total de horas: {{ decimalToHours(totalHorasC) }} </template>
                    </DataTable>
                    <p class="font-italic text-center" v-else>Não existem atividades adicionadas.</p>
                </div>
                <!-- <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="solucao.atividadeVenda" :binary="true" />
                    <label for="binary">Só é possível selecionar 1 atividade para venda</label>
                </div> -->
                <h5>Valores</h5>
                <div class="grid col-10">
                    <div class="field col-10 md:col-3">
                        <label>Valor Total</label>
                        <InputNumber v-model="solucao.valorTotal" mode="currency" currency="BRL" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" />
                    </div>
                    <div class="field col-10 md:col-3">
                        <label>Subsídio(%)</label>
                        <InputNumber v-model="solucao.subsidio" suffix="%" :max="100" :min="0" />
                    </div>
                    <div class="field col-10 md:col-3">
                        <label>Total Pago Sebrae</label>
                        <InputNumber
                            v-model="totalPagoSebrae"
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            disabled
                        />
                    </div>
                    <div class="field col-10 md:col-3">
                        <label>Total Pago Cliente</label>
                        <InputNumber
                            v-model="solucao.preco"
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            disabled
                        />
                    </div>
                </div>

                <h5>Produto de Entrega</h5>
                <div class="field col-12 md:col-12">
                    <div class="grid row align-items-center">
                        <div class="field col-12 md:col-9">
                            <label>Descrição</label>
                            <Textarea v-model="solucaoProduto.descricao" @input="v$.solucaoProduto.descricao.$touch()" :autoResize="true" rows="4" cols="30" />
                        </div>
                        <div class="field mt-4 col-12 md:col-3">
                            <Button label="Cancelar" v-if="isEditarProduto" @click.stop="cancelar('produto')" class="p-button-secondary mb-2"></Button>
                            <Button
                                :label="isEditarProduto ? 'Atualizar' : 'Adicionar'"
                                @click.stop="inserirProduto"
                                :disabled="v$.solucaoProduto.descricao.$invalid"
                            ></Button>
                        </div>
                    </div>
                    <DataTable v-if="solucao.descricaoProduto.length > 0" :value="solucao.descricaoProduto" responsiveLayout="scroll">
                        <Column field="descricao" header="Descrição"></Column>
                        <Column header="Ações">
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="p-button-text p-button-warning mr-2" @click="editarProduto(slotProps.data, index)" />
                                <Button icon="pi pi-trash" class="p-button-text p-button-danger" @click="excluirProduto(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                    <p class="font-italic text-center" v-else>Não existem produtos de entrega adicionados.</p>
                </div>
                <!-- <div class="field col-12 md:col-12">
                    <label>Tema</label>
                    <MultiSelect v-model="solucao.temas" :options="tema" optionLabel="title" />
                </div> -->
                <div class="field col-12 md:col-12">
                    <label for="minmax-buttons">Número Mínimo de Participantes</label>
                    <InputNumber
                        id="minmax-buttons"
                        v-model="solucao.minimoParticipantes"
                        v-bind:class="{ 'p-invalid': precisaParticipante }"
                        mode="decimal"
                        showButtons
                        :min="0"
                    />
                </div>
                <div class="field col-12 md:col-12">
                    <label for="minmax-buttons">Número Máximo de Participantes</label>
                    <InputNumber
                        id="minmax-buttons"
                        v-model="solucao.maximoParticipantes"
                        v-bind:class="{ 'p-invalid': precisaParticipante }"
                        mode="decimal"
                        showButtons
                        :min="0"
                    />
                </div>
                <span class="font-italic text-center" v-if="precisaParticipante"
                    >Número de participantes é obrigatório quando Instrumento for Presencial ou Ambos</span
                >
            </div>
            <div class="flex mr-5 justify-content-end">
                <Button label="Próximo" @click.stop="proximo" icon="pi pi-arrow-right" iconPos="right" :disabled="precisaParticipante == true"></Button>
            </div>
            <ScrollTop :threshold="100" class="mb-5 custom-scrolltop" icon="pi pi-arrow-up" />
        </div>
    </div>
</template>

<script>
import DorService from '../dores/service';
import AgrupadorService from '../agrupador/service';
import TipoServicoService from '../tiposervico/service';
import TemaService from '../tema/service';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
    props: {
        objeto: Object,
        onInserir: Boolean,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            participanteValido: false,
            solucaoDor: {
                dor: null,
                quantoResolve: null,
            },
            atividadeSolucao: {
                descricao: null,
                quantidadeHora: null,
                valorHora: null,
                valorEtapa: null,
                agrupador: null,
                tipoServico: null,
                remuneracaoCredenciado: null,
            },
            solucaoProduto: {
                descricao: null,
            },
            solucao: {
                valorTotal: null,
                subsidio: null,
                title: null,
                nomeComercial: null,
                tipoServico: null,
                dores: [],
                temas: [],
                atividades: [],
                descricaoProduto: [],
                justificativa: null,
                resultadoEsperado: null,
                descricaoAtividadesMateriais: null,
                produtoEntrega: null,
                atualizarValorHora: null,
                atividadeVenda: null,
                minimoParticipantes: null,
                maximoParticipantes: null,
                preco: null,
            },
            hora: null,
            dor: null,
            tipoServico: null,
            agrupador: null,
            tema: null,
            totalHoras: null,
            quantoResolve: null,
            isEditarAtividade: false,
            isEditarProduto: false,
            index: null,
            totalPagoSebrae: null,
            precisaParticipante: false,
        };
    },
    validations() {
        return {
            solucao: {
                title: { required },
                resultadoEsperado: { required },
                tipoServico: { required },
            },
            solucaoProduto: {
                descricao: { required },
            },
            atividadeSolucao: {
                descricao: { required },
                quantidadeHora: { required },
                valorHora: { required },
                valorEtapa: { required },
                tipoServico: { required },
            },
            solucaoDor: {
                dor: { required },
                quantoResolve: { required },
            },
        };
    },
    computed: {
        totalHorasC() {
            if (this.solucao.atividades.length > 0) {
                let totalHoras = 0;
                this.solucao.atividades.forEach((element) => {
                    totalHoras += element.quantidadeHora;
                });
                return totalHoras;
            }
            return 0;
        },
    },
    mounted() {
        this.solucao = this.objeto;
        this.obterDores();
        this.obterAgrupador();
        this.obterTipoServico();
        this.obterTema();
    },
    methods: {
        proximo() {
            this.$emit('proximo', this.solucao);
        },
        inserirSolucaoDor() {
            let dorExiste = this.solucao.dores.some((solucaoDor) => solucaoDor.dor.id == this.solucaoDor.dor.id);
            if (!dorExiste) {
                let solucaoDor = {
                    dor: this.solucaoDor.dor,
                    quantoResolve: this.solucaoDor.quantoResolve,
                };
                this.solucao.dores.push(solucaoDor);
            } else {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Alerta',
                    detail: 'Dor ja cadastrada',
                    life: 3000,
                });
            }
        },
        editarDor(objeto) {
            this.solucaoDor = objeto;
            this.excluirDor(objeto);
        },
        excluirDor(objeto) {
            let index = this.solucao.dores.indexOf(objeto);
            this.solucao.dores.splice(index, 1);
        },
        inserirAtividades() {
            if (this.isEditarAtividade) {
                this.isEditarAtividade = false;
                this.solucao.atividades[this.index].descricao = this.atividadeSolucao.descricao;
                this.solucao.atividades[this.index].quantidadeHora = this.atividadeSolucao.quantidadeHora;
                this.solucao.atividades[this.index].valorHora = this.atividadeSolucao.valorHora;
                this.solucao.atividades[this.index].valorEtapa = this.atividadeSolucao.valorEtapa;
                this.solucao.atividades[this.index].agrupador = this.atividadeSolucao.agrupador;
                this.solucao.atividades[this.index].tipoServico = this.atividadeSolucao.tipoServico;
                this.solucao.atividades[this.index].remuneracaoCredenciado = this.atividadeSolucao.remuneracaoCredenciado;
                this.cancelar('atividade');
            } else {
                let atividades = {
                    descricao: this.atividadeSolucao.descricao,
                    quantidadeHora: this.atividadeSolucao.quantidadeHora,
                    valorHora: this.atividadeSolucao.valorHora,
                    valorEtapa: this.atividadeSolucao.valorEtapa,
                    agrupador: this.atividadeSolucao.agrupador,
                    tipoServico: this.atividadeSolucao.tipoServico,
                    remuneracaoCredenciado: this.atividadeSolucao.remuneracaoCredenciado,
                };
                this.solucao.atividades.push(atividades);
                this.cancelar('atividade');
            }
        },
        editarAtividade(atividade) {
            this.isEditarAtividade = true;
            this.index = this.solucao.atividades.indexOf(atividade);
            let atividades = {
                descricao: atividade.descricao,
                quantidadeHora: atividade.quantidadeHora,
                valorHora: atividade.valorHora,
                valorEtapa: atividade.valorEtapa,
                agrupador: atividade.agrupador,
                tipoServico: atividade.tipoServico,
                remuneracaoCredenciado: atividade.remuneracaoCredenciado,
            };
            this.atividadeSolucao = atividades;
            this.hora = this.decimalToHours(atividades.quantidadeHora);
        },
        cancelar(tipo) {
            if (tipo == 'atividade') {
                this.isEditarAtividade = false;
                this.atividadeSolucao.descricao = null;
                this.atividadeSolucao.quantidadeHora = null;
                this.atividadeSolucao.agrupador = null;
                this.atividadeSolucao.valorHora = null;
                this.atividadeSolucao.tipoServico = null;
                this.atividadeSolucao.valorEtapa = null;
                this.atividadeSolucao.remuneracaoCredenciado = null;
                this.hora = null;
            }
            if (tipo == 'produto') {
                this.isEditarProduto = false;
                this.solucaoProduto.descricao = null;
            }
        },
        excluirAtividade(objeto) {
            let index = this.solucao.atividades.indexOf(objeto);
            this.solucao.atividades.splice(index, 1);
        },
        inserirProduto() {
            if (this.isEditarProduto) {
                this.isEditarProduto = false;
                this.solucao.descricaoProduto[this.index].descricao = this.solucaoProduto.descricao;
                this.cancelar('produto');
            } else {
                let produto = { descricao: this.solucaoProduto.descricao };
                this.solucao.descricaoProduto.push(produto);
                this.cancelar('produto');
            }
        },
        editarProduto(produto) {
            this.isEditarProduto = true;
            this.index = this.solucao.descricaoProduto.indexOf(produto);
            let descricaoProduto = { descricao: produto.descricao };
            this.solucaoProduto = descricaoProduto;
        },
        excluirProduto(objeto) {
            let index = this.solucao.descricaoProduto.indexOf(objeto);
            this.solucao.descricaoProduto.splice(index, 1);
        },
        obterDores() {
            this.$store.dispatch('addRequest');
            DorService.obterTodos().then((response) => {
                if (response?.success) {
                    this.dor = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterAgrupador() {
            this.$store.dispatch('addRequest');
            AgrupadorService.obterTodos().then((response) => {
                if (response?.success) {
                    this.agrupador = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterTipoServico() {
            this.$store.dispatch('addRequest');
            TipoServicoService.obterTodos().then((response) => {
                if (response?.success) {
                    this.tipoServico = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterTema() {
            this.$store.dispatch('addRequest');
            TemaService.obterTodos().then((response) => {
                if (response?.success) {
                    this.tema = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        inputNumber(event, objeto, variavel, validacao) {
            objeto[variavel] = event.value;
            validacao.$touch();
        },
        validarParticipantes(obj1, obj2, msg = 'Número Mínimo de Participantes não deve ser maior que o Número Máximo de Participantes') {
            if (obj1 != null && obj2 != null) {
                if (obj2 > obj1) {
                    this.participanteValido = false;
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `${msg}`,
                        life: 7000,
                    });
                }
                if (obj1 >= obj2) {
                    this.participanteValido = true;
                }
            }
        },
        decimalToHours(decimal) {
            const horas = Math.floor(decimal);
            const minutos = Math.round((decimal - horas) * 60);
            return `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
        },
        formataValores(valor) {
            if (!valor) {
                valor = 0;
            }
            valor = valor.toFixed(2).split('.');
            valor[0] = valor[0].split(/(?=(?:...)*$)/).join('.');
            return valor.join(',');
        },
        calcularPrecos() {
            this.totalPagoSebrae = (this.solucao.subsidio / 100) * this.solucao.valorTotal;
            this.solucao.preco = this.solucao.valorTotal - this.totalPagoSebrae;
        },
        valdiarModalidade() {
            if (this.solucao.tipoServico.modalidadeTipoServico.title == 'PRESENCIAL' || this.solucao.tipoServico.modalidadeTipoServico.title == 'AMBOS') {
                this.precisaParticipante = true;
            } else {
                this.precisaParticipante = false;
            }
        },
    },

    watch: {
        solucao: {
            handler() {
                this.$emit('salvar', this.solucao);
            },
            deep: true,
        },
        'solucao.maximoParticipantes'() {
            this.validarParticipantes(this.solucao.maximoParticipantes, this.solucao.minimoParticipantes);
        },
        'solucao.minimoParticipantes'() {
            this.validarParticipantes(this.solucao.maximoParticipantes, this.solucao.minimoParticipantes);
        },
        onInserir() {
            if (this.onInserir == true) {
                this.v$.solucao.$touch();
            }
        },
        'atividadeSolucao.agrupador'() {
            if (this.atividadeSolucao.agrupador) {
                this.atividadeSolucao.valorHora = this.atividadeSolucao.agrupador.valorHora;
            }
            if (this.atividadeSolucao.valorHora && this.atividadeSolucao.quantidadeHora) {
                this.atividadeSolucao.valorEtapa = this.atividadeSolucao.valorHora * this.atividadeSolucao.quantidadeHora;
            }
        },
        'atividadeSolucao.quantidadeHora'() {
            if (this.atividadeSolucao.quantidadeHora && this.atividadeSolucao.valorHora) {
                this.atividadeSolucao.valorEtapa = this.atividadeSolucao.valorHora * this.atividadeSolucao.quantidadeHora;
            } else {
                this.atividadeSolucao.valorEtapa = null;
            }
        },
        'atividadeSolucao.valorEtapa'() {
            this.atividadeSolucao.remuneracaoCredenciado = this.atividadeSolucao.valorEtapa;
        },
        'atividadeSolucao.valorHora'() {
            if (!this.atividadeSolucao.agrupador) {
                this.atividadeSolucao.valorEtapa = this.atividadeSolucao.valorHora * this.atividadeSolucao.quantidadeHora;
            }
        },
        hora() {
            if (this.hora) {
                var horasMinutos = this.hora.split(':');
                var horas = parseInt(horasMinutos[0], 10);
                var minutos = parseInt(horasMinutos[1], 10);

                if (minutos > 59) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Alerta',
                        detail: 'Quantidade de minutos não pode ser maior que 59',
                        life: 3000,
                    });
                    this.hora = null;
                } else {
                    this.atividadeSolucao.quantidadeHora = horas + minutos / 60;
                }
            } else {
                this.atividadeSolucao.quantidadeHora = this.hora;
            }
        },
        quantoResolve() {
            this.solucaoDor.quantoResolve = this.quantoResolve;
        },
        'solucao.valorTotal'() {
            this.calcularPrecos();
        },
        'solucao.subsidio'() {
            this.calcularPrecos();
        },
        'solucao.tipoServico'() {
            this.valdiarModalidade();
        },
    },
};
</script>
<style scoped>
.p-editor-container1 {
    border: 0.1px solid rgb(255, 81, 69);
    border-radius: 4px;
}
</style>

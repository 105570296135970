<template>
    <div class="card p-fluid">
        <h5>Fornecedor / Contratação</h5>
        <div class="field col-12 md:col-12">
            <label>Origem de Contratação</label>
            <Dropdown v-model="solucao.origemContratacao" :options="origemContratacao" optionLabel="title" showClear />
        </div>
        <!-- <div class="field-checkbox col-10">
            <Checkbox v-model="solucao.necessitaAprovacao" id="binary" :binary="true" />
            <label for="binary">Solução necessita de aprovação da USEE</label>
        </div> -->
        <div class="field col-12 md:col-12">
            <label>Perfil Exigido para o credenciado</label>
            <Editor v-model="solucao.perfilExigido" editorStyle="height: 120px" />
        </div>
        <div class="field col-12 md:col-12">
            <label>Contratação de Credenciados</label>
            <Dropdown v-model="solucao.contratacaoCredenciados" :options="contratacaoCredenciados" optionLabel="title" showClear />
        </div>
        <div class="field col-12 md:col-12">
            <div class="grid row align-items-end">
                <div class="field col-12 md:col-4">
                    <label class="required">Área</label>
                    <Dropdown
                        v-model="areas"
                        :options="area"
                        optionLabel="title"
                        showClear
                        v-bind:class="{ 'p-invalid': v$.areas.$invalid && v$.areas.$dirty && solucao.subAreas.length < 1 }"
                        @input="v$.areas.$touch()"
                    />
                </div>
                <div class="field col-12 md:col-4">
                    <label class="required">Sub-Área</label>
                    <Dropdown v-model="solucaoAreas.subArea" :options="subArea" optionLabel="title" showClear />
                </div>
                <div class="field col-12 md:col-2">
                    <label for="prioridade">Prioridade</label>
                    <Dropdown v-model="solucaoAreas.prioridade" :options="removePrioridadesSelecionadas(prioridade, solucao?.subAreas)" />
                </div>
                <div class="field flex row col-12 md:col-2">
                    <Button label="Cancelar" v-if="isEditar" @click.stop="cancelar()" class="p-button-secondary mr-2"></Button>
                    <Button
                        :label="isEditar ? 'Atualizar' : 'Adicionar'"
                        @click.stop="inserirSolucaoAreas($event)"
                        :disabled="v$.areas.$invalid || v$.solucaoAreas.$invalid"
                    ></Button>
                </div>
            </div>
            <DataTable v-if="solucao.subAreas.length > 0" :value="solucao.subAreas" key="index" responsiveLayout="scroll">
                <Column field="prioridade" header=""></Column>
                <Column field="subArea.area.title" header="Área"></Column>
                <Column field="subArea.title" bodyClass="" header="Sub-Área"> </Column>
                <Column header="Ações">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-text p-button-warning mr-2" @click="editarSolucaoAreas(slotProps.data)" />
                        <Button icon="pi pi-trash" class="p-button-text p-button-danger" @click="excluirSolucaoAreas(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
            <p v-else class="font-italic text-center">Não existem áreas adicionadas.</p>
        </div>
        <!-- <div class="field-checkbox col-10">
            <Checkbox id="binary" v-model="solucao.valorVemEmpresa" :binary="true" />
            <label for="binary">Valor da Solução vem da Empresa</label>
        </div> -->
    </div>
    <div class="ml-6">
        <div class="flex mr-5 justify-content-end">
            <Button label="Voltar" @click.stop="voltar" icon="pi pi-arrow-left" class="ml-2 p-button-secondary"></Button>
            <Button class="ml-2" label="Próximo" icon="pi pi-arrow-right" iconPos="right" @click.stop="proximo"></Button>
        </div>
    </div>
</template>

<script>
import OrigemContratacaoService from '../origemcontratacao/service';
import ContratacaoCredenciadoService from '../contratacaocredenciado/service';
import AreaService from '../area/service';
import SubAreaService from '../subarea/service';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
    emits: ['voltar', 'proximo', 'salvar'],
    props: {
        objeto: Object,
        onInserir: Boolean,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            areas: null,
            solucaoAreas: {
                subArea: {},
                prioridade: null,
            },
            solucao: {
                origemContratacao: null,
                contratacaoCredenciados: null,
                necessitaAprovacao: null,
                perfilExigido: null,
                valorVemEmpresa: null,
                subAreas: [],
            },
            origemContratacao: null,
            contratacaoCredenciados: null,
            area: null,
            subArea: null,
            subAreaOriginal: null,
            empresasCredenciados: null,
            materialDidatico: null,
            isEditar: false,
            index: null,
            prioridade: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        };
    },
    validations() {
        return {
            areas: { required },
            solucaoAreas: { required },
        };
    },
    mounted() {
        this.solucao = this.objeto;
        this.obterOrigemContratacao();
        this.obterContratacaoCredenciado();
        this.obterArea();
        this.obterSubArea();
    },
    methods: {
        proximo() {
            this.$emit('proximo');
        },
        voltar() {
            this.$emit('voltar');
        },
        inserirSolucaoAreas() {
            let areaExiste = this.solucao.subAreas.some((subArea) => subArea.subArea?.id == this.solucaoAreas?.subArea?.id);

            if (!areaExiste) {
                if (this.isEditar) {
                    this.isEditar = false;
                    this.solucao.subAreas[this.index] = this.solucaoAreas;
                    this.cancelar();
                } else {
                    let solucaoAreas = this.solucaoAreas;
                    this.solucao.subAreas.push(solucaoAreas);
                    this.cancelar();
                }
            } else {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Alerta',
                    detail: 'Sub-Área ja cadastrada',
                    life: 3000,
                });
            }
        },
        cancelar() {
            this.isEditar = false;
            this.areas = null;
            this.solucaoAreas = {
                subArea: {},
                prioridade: null,
            };
        },

        editarSolucaoAreas(objeto) {
            this.isEditar = true;
            this.index = this.solucao.subAreas.indexOf(objeto);
            let areaEdit = objeto;
            this.areas = objeto.area;
            this.solucaoAreas = areaEdit;
        },
        excluirSolucaoAreas(objeto) {
            let index = this.solucao.subAreas.indexOf(objeto);
            this.solucao.subAreas.splice(index, 1);
        },
        obterOrigemContratacao() {
            this.$store.dispatch('addRequest');
            OrigemContratacaoService.obterTodos().then((response) => {
                if (response?.success) {
                    this.origemContratacao = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterContratacaoCredenciado() {
            this.$store.dispatch('addRequest');
            ContratacaoCredenciadoService.obterTodos().then((response) => {
                if (response?.success) {
                    this.contratacaoCredenciados = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterArea() {
            this.$store.dispatch('addRequest');
            AreaService.obterTodos().then((response) => {
                if (response?.success) {
                    this.area = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterSubArea() {
            this.$store.dispatch('addRequest');
            SubAreaService.obterTodos().then((response) => {
                if (response?.success) {
                    this.subAreaOriginal = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        removePrioridadesSelecionadas(prioridades, subAreas) {
            if (!subAreas) {
                return prioridades;
            }
            return prioridades.filter((prioridade) => !subAreas.some((subArea) => subArea.prioridade === prioridade));
        },
    },
    watch: {
        solucao: {
            handler() {
                this.$emit('salvar', this.solucao);
            },
            deep: true,
        },
        'solucao.subAreas': {
            handler() {
                if (this.solucao?.subAreas) {
                    this.solucao?.subAreas.sort((a, b) => {
                        if (a.prioridade === 0) {
                            return Infinity;
                        } else if (b.prioridade === 0) {
                            return -Infinity;
                        }

                        if (a.prioridade === b.prioridade) {
                            return 0;
                        }
                        return a.prioridade - b.prioridade;
                    });
                }
            },
            deep: true,
        },
        areas: {
            handler() {
                if (this.areas) {
                    let filtrarArea = this.subAreaOriginal.filter((res) => res.area.id == this.areas.id);
                    this.subArea = filtrarArea;
                    if (!this.subArea.some((subArea) => subArea.id == this.solucaoAreas?.subArea?.id)) {
                        this.solucaoAreas.subArea.id = null;
                    }
                } else {
                    this.subArea = null;
                }
            },
        },
        onInserir() {
            if (this.onInserir == true) {
                this.v$.areas.$touch();
                this.v$.solucaoAreas.$touch();
            }
        },
    },
};
</script>

<style></style>

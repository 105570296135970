<template>
    <div>
        <div class="card">
            <div class="flex px-3 mt-2 justify-content-between">
                <h4 class="mt-2">{{ solucao.id ? 'Atualizar Solução' : 'Nova Solução' }}</h4>
                <div>
                    <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
                    <Button label="Salvar" @click.stop="inserir" icon="pi pi-check-circle"></Button>
                </div>
            </div>
            <TabView scrollable v-model:activeIndex="active">
                <TabPanel header="Informações Gerais">
                    <solucao-informacoes-gerais-form
                        v-if="isLoaded"
                        :onInserir="onInserir"
                        :objeto="solucao"
                        @voltar="voltar"
                        @proximo="proximo()"
                        @salvar="salvar"
                    />
                </TabPanel>
                <TabPanel header="Orientações para Oferta">
                    <solucao-orientacoes-oferta-form
                        v-if="isLoaded"
                        :objeto="solucao"
                        @proximo="proximo()"
                        :onInserir="onInserir"
                        @voltar="voltar"
                        @salvar="salvar"
                    />
                </TabPanel>
                <TabPanel header="Fornecedor / Contratação">
                    <solucao-fornecedor-contratacao-form
                        v-if="isLoaded"
                        :objeto="solucao"
                        @proximo="proximo()"
                        :onInserir="onInserir"
                        @voltar="voltar"
                        @salvar="salvar"
                    />
                </TabPanel>
                <TabPanel header="Outros">
                    <solucao-outros-form v-if="isLoaded" :objeto="solucao" @proximo="proximo()" :onInserir="onInserir" @voltar="voltar" @salvar="salvar" />
                </TabPanel>
                <TabPanel header="Integração SAS">
                    <solucao-integracao-sas-form
                        v-if="isLoaded"
                        :objeto="solucao"
                        :isInserir="onInserir"
                        @voltar="voltar"
                        @salvar="salvar"
                        @inserir="inserir"
                    />
                </TabPanel>
            </TabView>
        </div>
        <solucao-modal-atualizacao v-if="modal" :modal="modal" :objeto="solucaoId" @closeModal="modal = false" @salvar="salvar" @atualizar="atualizar" />
    </div>
</template>

<script>
import Services from './service';
import SolucaoFornecedorContratacaoForm from './SolucaoFornecedorContratacaoForm.vue';
import SolucaoModalAtualizacao from './SolucaoModalAtualizacao.vue';
import SolucaoInformacoesGeraisForm from './SolucaoInformacoesGeraisForm.vue';
import SolucaoIntegracaoSasForm from './SolucaoIntegracaoSasForm.vue';
import SolucaoOrientacoesOfertaForm from './SolucaoOrientacoesOfertaForm.vue';
import SolucaoOutrosForm from './SolucaoOutrosForm.vue';

export default {
    components: {
        SolucaoInformacoesGeraisForm,
        SolucaoOrientacoesOfertaForm,
        SolucaoFornecedorContratacaoForm,
        SolucaoOutrosForm,
        SolucaoIntegracaoSasForm,
        SolucaoModalAtualizacao,
    },
    data() {
        return {
            onInserir: false,
            active: 0,
            isLoaded: false,
            modal: false,
            solucaoId: null,
            solucao: {
                title: null,
                nomeComercial: null,
                tipoServico: null,
                politicaPreco: null,
                origemContratacao: null,
                contratacaoCredenciados: null,
                tipoExecucao: null,
                complexidade: null,
                metodologia: null,
                complexidadeMetodologia: null,
                temas: [],
                publicoAlvo: [],
                macrossegmentosEconomicos: [],
                personas: [],
                setoresEconomicos: [],
                codigoUnidadeOrganizacional: null,
                unidadeOrganizacional: null,
                dores: [],
                atividades: [],
                descricaoProduto: [],
                justificativa: null,
                resultadoEsperado: null,
                descricaoAtividadesMateriais: null,
                produtoEntrega: null,
                atualizarValorHora: null,
                atividadeVenda: null,
                minimoParticipantes: null,
                maximoParticipantes: null,
                textoVenda: null,
                observacoesOrientacoes: null,
                necessitaAprovacao: null,
                perfilExigido: null,
                valorVemEmpresa: null,
                subAreas: [],
                palavrasChave: null,
                emiteCertificado: null,
                urlSolucao: null,
                solucaoCertificadaSebrae: null,
                valorTotal: null,
                subsidio: null,
                preco: null,
            },
        };
    },
    mounted() {
        if (this.$route.params.chamado) {
            let chamado = JSON.parse(this.$route.params.chamado);

            this.solucao.title = chamado.origem.solicitacaoProdutoReal.eventoNome;
            this.solucao.emiteCertificado = chamado.origem.solicitacaoProdutoReal.geraCertificado == 'Sim' ? true : false;
            this.solucao.textoVenda = chamado.origem.solicitacaoProdutoReal.argumentoVenda;
        }
        if (this.isAtualizar) {
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.solucao = response.data;
                    this.isLoaded = true;
                }
            });
        } else {
            this.isLoaded = true;
        }
    },
    computed: {
        isAtualizar() {
            return this.$route.params.id;
        },
    },
    methods: {
        inserir() {
            this.onInserir = true;
            if (this.isAtualizar) {
                this.modal = true;
            } else {
                this.$store.dispatch('addRequest');
                Services.inserir(this.solucao).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        atualizar(solucaoAlterada) {
            this.solucao = { ...this.solucao, ...solucaoAlterada };
            this.modal = false;
            this.$store.dispatch('addRequest');
            Services.atualizar(this.solucao.id, this.solucao).then((response) => {
                this.respostaSalvar(response, true);
            });
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$store.dispatch('setAtualizar', true);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Solução',
                    detail: `Solução ${edicao ? 'editada' : 'inserida'} com sucesso`,
                    life: 3000,
                });
                this.cancelar();
            } else {
                this.erros = response.errors[0].split(',');
                this.modal = false;

                if (this.erros.length < 4) {
                    this.erros.forEach((element) => {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Erro',
                            detail: element,
                            life: 15000,
                        });
                    });
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Os campos em vermelho são obrigatórios',
                        life: 15000,
                    });
                }
            }
            this.$store.dispatch('removeRequest');
        },
        cancelar() {
            this.$router.push({
                name: `Solucoes`,
            });
        },
        salvar(solucaoAlterada) {
            this.solucao = { ...this.solucao, ...solucaoAlterada };
        },
        proximo() {
            if (this.active < 4) {
                this.active++;
            }
        },
        voltar() {
            if (this.active > 0) {
                this.active--;
            }
        },
    },
};
</script>

<style></style>
